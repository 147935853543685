import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getTempCoupons } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
// do the logic for queries -> bonuses -> getTempCoupons
const useLandingCouponsStore = defineStore('landing-coupons-store', ()=>{
    const apiClient = useGraphqlClient();
    const coupons = ref([]);
    async function fetchTempCoupons(encryptedCustomerId) {
        const response = await getTempCoupons(apiClient, (node)=>node.queries.bonuses.getTempCoupons, {
            options: {
                encryptedCustomerId
            }
        });
        coupons.value = response.coupons;
    }
    return {
        fetchTempCoupons,
        coupons
    };
});
export default useLandingCouponsStore;
