import { ref } from 'vue';
export default function useLandingListPanelTab() {
    const activeIndex = ref(0);
    const swiperMain = ref(null);
    function setActiveSlide(index) {
        activeIndex.value = index;
    }
    function emitSlideChanged(event) {
        activeIndex.value = event.activeSlide;
    }
    return {
        swiperMain,
        activeIndex,
        setActiveSlide,
        emitSlideChanged
    };
}
