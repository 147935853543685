import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { IconSize, SystemIconName } from '@leon-hub/icons';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { VIcon } from '@components/v-icon';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import { ModalHeight, ModalWidth } from 'web/src/components/Modal/enums';
import OverlayType from 'web/src/components/Modal/VOverlay/enums/OverlayType';
import { LandingCouponsModalType } from 'web/src/modules/landings/submodules/coupons/pages/components/LandingCouponsModal/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingCouponsModal',
    props: {
        coupons: {},
        isModalShown: {
            type: Boolean
        },
        isLarge: {
            type: Boolean
        },
        hideIcons: {
            type: Boolean
        },
        type: {
            default: LandingCouponsModalType.TEMP
        },
        title: {},
        description: {}
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const analytics = useAnalytics();
        const modalProperties = computed(()=>({
                fullHeight: false,
                isFullHeightCentered: false,
                title: '',
                isCloseAllowed: true,
                isOverlayCloseAllowed: true,
                buttons: [],
                width: props.isLarge ? ModalWidth.MEDIUM_PLUS : ModalWidth.MEDIUM,
                customBackground: 'var(--DLayer0)',
                height: ModalHeight.MEDIUM,
                overlayType: OverlayType.BLUR_L0
            }));
        function onCouponClick() {
            analytics.push(AnalyticsEvent.Z_MISCLICK_MY_COUPONS_EURO, {
                euro2024: {
                    auth: 'misclick_coupon_number'
                }
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(ModalSelector).BODY
            }, {
                default: _withCtx(()=>[
                        _ctx.isModalShown ? (_openBlock(), _createBlock(ConfirmModal, {
                            key: 0,
                            modal: modalProperties.value,
                            "is-alert": true,
                            "is-scroll-bar-enabled": "",
                            onClose: _cache[0] || (_cache[0] = ($event)=>emit('close'))
                        }, {
                            default: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass([
                                            [
                                                _ctx.$style['landing-coupons-details-page']
                                            ],
                                            [
                                                _ctx.$style[`landing-coupons-details-page--type-${_ctx.type}`]
                                            ]
                                        ])
                                    }, [
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['landing-coupons-details-page__header'])
                                        }, [
                                            _createElementVNode("h2", {
                                                class: _normalizeClass(_ctx.$style['landing-coupons-details-page__title'])
                                            }, _toDisplayString(_ctx.title || _ctx.$t('WEB2_LANDING_COUPON_TITLE')), 3),
                                            _createElementVNode("p", {
                                                class: _normalizeClass(_ctx.$style['landing-coupons-details-page__label'])
                                            }, _toDisplayString(_ctx.description || _ctx.$t('WEB2_LANDING_COUPON_LABEL')), 3)
                                        ], 2),
                                        _createElementVNode("div", {
                                            class: _normalizeClass({
                                                [_ctx.$style['landing-coupons-details-page__list']]: true,
                                                [_ctx.$style['landing-coupons-details-page__list--large']]: _ctx.isLarge,
                                                [_ctx.$style['landing-coupons-details-page__list--single']]: 1 === _ctx.coupons.length
                                            })
                                        }, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coupons, (item)=>(_openBlock(), _createElementBlock("div", {
                                                    key: item,
                                                    class: _normalizeClass({
                                                        [_ctx.$style['landing-coupons-details-page__item']]: true,
                                                        [_ctx.$style['landing-coupons-details-page__item--hide-icons']]: _ctx.hideIcons
                                                    }),
                                                    onClick: onCouponClick
                                                }, [
                                                    _ctx.hideIcons ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                                                        key: 0,
                                                        class: _normalizeClass(_ctx.$style['landing-coupons-details-page__icon-container'])
                                                    }, [
                                                        _createVNode(_unref(VIcon), {
                                                            class: _normalizeClass(_ctx.$style['landing-coupons-details-page__icon']),
                                                            size: _unref(IconSize).SIZE_24,
                                                            name: _unref(SystemIconName).TICKET_CONFIRMATION
                                                        }, null, 8, [
                                                            "class",
                                                            "size",
                                                            "name"
                                                        ])
                                                    ], 2)),
                                                    _createElementVNode("span", null, _toDisplayString(`${item}`.replace(/(\d{3})(?=\d)/g, "$1 ")), 1)
                                                ], 2))), 128))
                                        ], 2)
                                    ], 2)
                                ]),
                            _: 1
                        }, 8, [
                            "modal",
                            "is-alert"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "selector"
            ])), [
                [
                    _directive_auto_id,
                    'LandingCouponsModal'
                ]
            ]);
        };
    }
});
