import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import LandingChevron from 'web/src/modules/landings/system/components/LandingChevron/LandingChevron.vue';
import LandingTitle from 'web/src/modules/landings/system/components/LandingTitle/LandingTitle.vue';
import { TitleKind } from 'web/src/modules/landings/system/components/LandingTitle/types';
import LandingLabel from 'web/src/modules/landings/system/components/LandingLabel/LandingLabel.vue';
import { LabelKind } from 'web/src/modules/landings/system/components/LandingLabel/types';
import LandingButton from 'web/src/modules/landings/system/components/LandingButton/LandingButton.vue';
import LandingAppList from 'web/src/modules/landings/system/components/LandingAppList/LandingAppList.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingBanner',
    props: {
        chevron: {},
        title: {},
        label: {},
        buttonLabel: {},
        imageSrc: {},
        appList: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['landing-banner'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-banner__content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-banner__header'])
                    }, [
                        _ctx.chevron ? (_openBlock(), _createBlock(LandingChevron, {
                            key: 0,
                            label: _ctx.chevron
                        }, null, 8, [
                            "label"
                        ])) : _createCommentVNode("", true),
                        _ctx.title ? (_openBlock(), _createBlock(LandingTitle, {
                            key: 1,
                            content: _ctx.title,
                            kind: _unref(TitleKind).SECONDARY
                        }, null, 8, [
                            "content",
                            "kind"
                        ])) : _createCommentVNode("", true),
                        _ctx.label ? (_openBlock(), _createBlock(LandingLabel, {
                            key: 2,
                            content: _ctx.label,
                            kind: _unref(LabelKind).PRIMARY
                        }, null, 8, [
                            "content",
                            "kind"
                        ])) : _createCommentVNode("", true)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-banner__footer'])
                    }, [
                        _ctx.buttonLabel ? (_openBlock(), _createBlock(LandingButton, {
                            key: 0,
                            label: _ctx.buttonLabel
                        }, null, 8, [
                            "label"
                        ])) : _createCommentVNode("", true),
                        _ctx.appList ? (_openBlock(), _createBlock(LandingAppList, {
                            key: 1,
                            list: _ctx.appList
                        }, null, 8, [
                            "list"
                        ])) : _createCommentVNode("", true)
                    ], 2)
                ], 2),
                _ctx.imageSrc ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['landing-banner__image'])
                }, [
                    _createVNode(VImage, {
                        src: _ctx.imageSrc,
                        width: "100%"
                    }, null, 8, [
                        "src"
                    ])
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingBanner'
                ]
            ]);
        };
    }
});
