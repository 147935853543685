import { computed, toRef } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export const useLandingImages = ()=>{
    const siteConfigStore = useSiteConfigStore();
    const bgImage = toRef(siteConfigStore, 'landingCouponsBackground');
    const mainImage = toRef(siteConfigStore, 'landingCouponsMainImage');
    const secondaryImage = toRef(siteConfigStore, 'landingCouponsSecondaryImage');
    const srcCouponBackground = computed(()=>bgImage.value?.src || '');
    const srcCouponMainImage = computed(()=>mainImage.value?.src || '');
    const srcCouponSecondaryImage = computed(()=>secondaryImage.value?.src || '');
    return {
        srcCouponBackground,
        srcCouponMainImage,
        srcCouponSecondaryImage
    };
};
