import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import { LandingBrandSize } from 'web/src/modules/landings/system/components/LandingBrand/types';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingBrand',
    props: {
        brandHeight: {
            default: LandingBrandSize.HEIGHT_18
        },
        isDark: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['landing-brand']]: true,
                    [_ctx.$style[`landing-brand--${_ctx.brandHeight}`]]: _ctx.brandHeight
                })
            }, [
                _createVNode(VBrand, {
                    "is-dark": _ctx.isDark,
                    class: _normalizeClass(_ctx.$style['landing-brand__image'])
                }, null, 8, [
                    "is-dark",
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingBrand'
                ]
            ]);
        };
    }
});
