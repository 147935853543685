import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { LandingItemColorTheme, LandingItemDirection, LandingItemImageSize, LandingItemStep } from 'web/src/modules/landings/system/components/LandingListItem/types';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import LandingTitle from 'web/src/modules/landings/system/components/LandingTitle/LandingTitle.vue';
import LandingLabel from 'web/src/modules/landings/system/components/LandingLabel/LandingLabel.vue';
import { TitleKind } from 'web/src/modules/landings/system/components/LandingTitle/types';
import { LabelKind } from 'web/src/modules/landings/system/components/LandingLabel/types';
import { useLandingListItem } from 'web/src/modules/landings/system/components/LandingListItem/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingListItem',
    props: {
        index: {},
        imageSize: {
            default: LandingItemImageSize.SIZE_80
        },
        imageSrc: {},
        title: {},
        label: {},
        direction: {
            default: LandingItemDirection.ROW_TOP
        },
        step: {
            default: LandingItemStep.NONE
        },
        theme: {
            default: LandingItemColorTheme.PRIMARY
        }
    },
    setup (__props) {
        const props = __props;
        const { titleStep } = useLandingListItem(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['landing-list-item']]: true,
                    [_ctx.$style[`landing-list-item--${props.direction}`]]: props.direction !== _unref(LandingItemDirection).ROW_TOP,
                    [_ctx.$style[`landing-list-item--${props.theme}`]]: props.theme !== _unref(LandingItemColorTheme).PRIMARY
                })
            }, [
                _ctx.step === _unref(LandingItemStep).TITLE ? (_openBlock(), _createBlock(LandingTitle, {
                    key: 0,
                    content: _unref(titleStep),
                    "is-inverse": _ctx.theme === _unref(LandingItemColorTheme).SECONDARY,
                    kind: _unref(TitleKind).TERTIARY
                }, null, 8, [
                    "content",
                    "is-inverse",
                    "kind"
                ])) : _createCommentVNode("", true),
                _ctx.step === _unref(LandingItemStep).NUMBER ? (_openBlock(), _createBlock(LandingLabel, {
                    key: 1,
                    kind: _unref(LabelKind).NUMBER,
                    "is-inverse": _ctx.theme === _unref(LandingItemColorTheme).SECONDARY,
                    class: _normalizeClass(_ctx.$style['landing-list-item__step-number']),
                    content: `0${_ctx.index + 1}<span>.</span>`
                }, null, 8, [
                    "kind",
                    "is-inverse",
                    "class",
                    "content"
                ])) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-list-item__image'])
                }, [
                    _ctx.imageSrc ? (_openBlock(), _createBlock(VImage, {
                        key: 0,
                        src: _ctx.imageSrc,
                        width: _ctx.imageSize.split('-')[1]
                    }, null, 8, [
                        "src",
                        "width"
                    ])) : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-list-item__paragraph'])
                }, [
                    _ctx.title ? (_openBlock(), _createBlock(LandingTitle, {
                        key: 0,
                        content: _ctx.title,
                        "is-inverse": _ctx.theme === _unref(LandingItemColorTheme).SECONDARY,
                        kind: _unref(TitleKind).TERTIARY
                    }, null, 8, [
                        "content",
                        "is-inverse",
                        "kind"
                    ])) : _createCommentVNode("", true),
                    _ctx.label ? (_openBlock(), _createBlock(LandingLabel, {
                        key: 1,
                        content: _ctx.label,
                        "is-inverse": _ctx.theme === _unref(LandingItemColorTheme).SECONDARY,
                        kind: _unref(LabelKind).SECONDARY
                    }, null, 8, [
                        "content",
                        "is-inverse",
                        "kind"
                    ])) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingListItem'
                ]
            ]);
        };
    }
});
