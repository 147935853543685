import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import LandingButton from 'web/src/modules/landings/system/components/LandingButton/LandingButton.vue';
import { OrientationImage } from 'web/src/modules/landings/system/types';
import LandingChevron from 'web/src/modules/landings/system/components/LandingChevron/LandingChevron.vue';
import LandingTitle from 'web/src/modules/landings/system/components/LandingTitle/LandingTitle.vue';
import LandingLabel from 'web/src/modules/landings/system/components/LandingLabel/LandingLabel.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingHeroBorderedScreen',
    props: {
        imageSrc: {},
        bgSrcMobile: {},
        bgSrcDesk: {},
        imageKind: {
            default: OrientationImage.HORIZONTAL
        },
        chevron: {},
        title: {},
        label: {},
        buttonLabel: {},
        buttonIcon: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['landing-hero-bordered-screen'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-hero-bordered-screen__panel'])
                }, [
                    _ctx.bgSrcDesk || _ctx.bgSrcMobile ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['landing-hero-bordered-screen__background-image']),
                        style: _normalizeStyle({
                            backgroundImage: (_ctx.bgSrcDesk ? `url(${_ctx.bgSrcDesk})` : void 0) || void 0
                        })
                    }, null, 6)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-hero-bordered-screen__content'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['landing-hero-bordered-screen__content-header'])
                        }, [
                            _ctx.chevron ? (_openBlock(), _createBlock(LandingChevron, {
                                key: 0,
                                label: _ctx.chevron
                            }, null, 8, [
                                "label"
                            ])) : _createCommentVNode("", true),
                            _ctx.title ? (_openBlock(), _createBlock(LandingTitle, {
                                key: 1,
                                content: _ctx.title
                            }, null, 8, [
                                "content"
                            ])) : _createCommentVNode("", true)
                        ], 2),
                        _ctx.label ? (_openBlock(), _createBlock(LandingLabel, {
                            key: 0,
                            content: _ctx.label
                        }, null, 8, [
                            "content"
                        ])) : _createCommentVNode("", true),
                        _ctx.buttonLabel ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['landing-hero-bordered-screen__content-footer'])
                        }, [
                            _createVNode(LandingButton, {
                                label: _ctx.buttonLabel,
                                "icon-name": _ctx.buttonIcon
                            }, null, 8, [
                                "label",
                                "icon-name"
                            ])
                        ], 2)) : _createCommentVNode("", true)
                    ], 2),
                    _ctx.imageSrc ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['landing-hero-bordered-screen__image'])
                    }, [
                        _createElementVNode("img", {
                            src: _ctx.imageSrc,
                            width: "100%"
                        }, null, 8, _hoisted_1)
                    ], 2)) : _createCommentVNode("", true),
                    _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingHeroBorderedScreen'
                ]
            ]);
        };
    }
});
