import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { LabelKind } from 'web/src/modules/landings/system/components/LandingLabel/types';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingLabel',
    props: {
        content: {},
        kind: {
            default: LabelKind.PRIMARY
        },
        isInverse: {
            type: Boolean,
            default: false
        },
        isActive: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VDynamicContent, {
                class: _normalizeClass({
                    [_ctx.$style['landing-label']]: true,
                    [_ctx.$style['landing-label--active']]: _ctx.isActive,
                    [_ctx.$style['landing-label--inverse']]: _ctx.isInverse,
                    [_ctx.$style[`landing-label--${_ctx.kind}`]]: _ctx.kind
                }),
                content: _ctx.content
            }, null, 8, [
                "class",
                "content"
            ])), [
                [
                    _directive_auto_id,
                    'LandingLabel'
                ]
            ]);
        };
    }
});
