import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
import VImage from 'web/src/components/Image/VImage/VImage.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingAppList',
    props: {
        list: {},
        isCentered: {
            type: Boolean,
            default: false
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['landing-app-list']]: true,
                    [_ctx.$style['landing-app-list--centered']]: _ctx.isCentered
                })
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index)=>(_openBlock(), _createElementBlock("a", {
                        key: index,
                        href: item.link,
                        class: _normalizeClass(_ctx.$style['landing-app-list__item'])
                    }, [
                        _createVNode(VImage, {
                            height: "100%",
                            width: "auto",
                            src: item.src
                        }, null, 8, [
                            "src"
                        ])
                    ], 10, _hoisted_1))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingAppList'
                ]
            ]);
        };
    }
});
