import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import LandingTitle from 'web/src/modules/landings/system/components/LandingTitle/LandingTitle.vue';
import { TitleKind } from 'web/src/modules/landings/system/components/LandingTitle/types';
import LandingButton from 'web/src/modules/landings/system/components/LandingButton/LandingButton.vue';
import LandingAppList from 'web/src/modules/landings/system/components/LandingAppList/LandingAppList.vue';
import LandingBrand from 'web/src/modules/landings/system/components/LandingBrand/LandingBrand.vue';
import { LandingBrandSize } from 'web/src/modules/landings/system/components/LandingBrand/types';
import LandingSocialList from 'web/src/modules/landings/system/components/LandingSocialLink/LandingSocialList.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingFooter',
    props: {
        title: {},
        buttonLabel: {},
        appsList: {},
        iconName: {},
        isDarkBrand: {
            type: Boolean
        },
        socialList: {},
        copyrightList: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['landing-footer'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-footer__header'])
                }, [
                    _ctx.title ? (_openBlock(), _createBlock(LandingTitle, {
                        key: 0,
                        content: _ctx.title,
                        kind: _unref(TitleKind).PRIMARY
                    }, null, 8, [
                        "content",
                        "kind"
                    ])) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-footer__buttons'])
                    }, [
                        _ctx.buttonLabel ? (_openBlock(), _createBlock(LandingButton, {
                            key: 0,
                            label: _ctx.buttonLabel,
                            "icon-name": _ctx.iconName
                        }, null, 8, [
                            "label",
                            "icon-name"
                        ])) : _createCommentVNode("", true),
                        _ctx.appsList ? (_openBlock(), _createBlock(LandingAppList, {
                            key: 1,
                            list: _ctx.appsList,
                            "is-centered": ""
                        }, null, 8, [
                            "list"
                        ])) : _createCommentVNode("", true)
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-footer__body'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-footer__brand'])
                    }, [
                        _createVNode(LandingBrand, {
                            "is-dark": _ctx.isDarkBrand,
                            "brand-height": _unref(LandingBrandSize).HEIGHT_18
                        }, null, 8, [
                            "is-dark",
                            "brand-height"
                        ])
                    ], 2),
                    _ctx.socialList ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['landing-footer__socials'])
                    }, [
                        _createVNode(LandingSocialList, {
                            list: _ctx.socialList
                        }, null, 8, [
                            "list"
                        ])
                    ], 2)) : _createCommentVNode("", true),
                    _ctx.copyrightList ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['landing-footer__copyright'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.copyrightList, (item, index)=>(_openBlock(), _createElementBlock("span", {
                                key: index
                            }, _toDisplayString(item), 1))), 128))
                    ], 2)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingFooter'
                ]
            ]);
        };
    }
});
