export var LandingItemImageSize;
var LandingItemImageSize1;
(LandingItemImageSize1 = LandingItemImageSize || (LandingItemImageSize = {}))["SIZE_80"] = "size-80", LandingItemImageSize1["SIZE_128"] = "size-128", LandingItemImageSize1["SIZE_146"] = "size-146", LandingItemImageSize1["SIZE_180"] = "size-180", LandingItemImageSize1["SIZE_260"] = "size-260";
export var LandingItemDirection;
var LandingItemDirection1;
(LandingItemDirection1 = LandingItemDirection || (LandingItemDirection = {}))["ROW_TOP"] = "row-top", LandingItemDirection1["ROW_CENTER"] = "row-center", LandingItemDirection1["ROW_TOP_REVERSE"] = "row-top-reverse", LandingItemDirection1["ROW_CENTER_REVERSE"] = "row-center-reverse", LandingItemDirection1["COLUMN_LEFT"] = "column-left", LandingItemDirection1["COLUMN_CENTER"] = "column-center";
export var LandingItemStep;
var LandingItemStep1;
(LandingItemStep1 = LandingItemStep || (LandingItemStep = {}))["NONE"] = "none", LandingItemStep1["NUMBER"] = "number", LandingItemStep1["TITLE"] = "title";
export var LandingItemColorTheme;
var LandingItemColorTheme1;
(LandingItemColorTheme1 = LandingItemColorTheme || (LandingItemColorTheme = {}))["PRIMARY"] = "primary", LandingItemColorTheme1["SECONDARY"] = "secondary";
