/* eslint-disable class-methods-use-this */ import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { themeDark } from '@leon-hub/api-sdk';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
export class LandingsModule extends AppModule {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    addRoutes(router) {
        toRef(useSiteConfigStore(), 'isLandingFestivalEnabled');
        const isLandingVipEnabled = toRef(useSiteConfigStore(), 'isLandingVipEnabled');
        const isAdventCalendarEnabled = toRef(useSiteConfigStore(), 'isAdventCalendarEnabled');
        router.addLandingRoute({
            name: RouteName.COUPONS,
            path: '/my-coupons/:id?',
            component: ()=>import('./submodules/coupons/pages/LandingCouponsPage.vue')
        });
        "1";
        router.addLandingRoute({
            name: RouteName.ANDROID,
            path: '/android',
            component: ()=>import('./submodules/android/pages/AndroidRoutePage.vue')
        });
        if (isLandingVipEnabled.value) router.addDefaultRoute({
            name: RouteName.VIP,
            path: '/vip',
            component: ()=>import('./submodules/vip/pages/LandingVipRoutePage.vue'),
            isLeftSidebarToggleHidden: true
        });
        router.addLandingRoute({
            name: RouteName.LANDING_PRESET,
            path: '/landing-preset',
            component: ()=>import('./system/LandingRoutePreset.vue')
        });
        if (isAdventCalendarEnabled.value) {
            router.addDefaultRoute({
                name: RouteName.BONUS_CALENDAR,
                path: '/bonus-calendar',
                component: ()=>import('./submodules/advent/pages/AdventRoutePage/AdventRoutePage.vue'),
                theme: themeDark
            });
            router.addModalRoute({
                name: RouteName.BONUS_CALENDAR_PRIZES,
                path: '/bonus-calendar/prizes',
                access: CustomerRouteAccessRole.AUTHORIZED,
                modalPreset: ModalDesktopPreset.EuroBattlePassModal,
                title: '{{$t(\'WEB2_LANDING_ADVENT_MODAL_GET_PRIZES_TITLE\')}}',
                component: ()=>import('./submodules/advent/pages/AdventPrizesModalRoutePage/AdventPrizesModalRoutePage.vue'),
                topBar: void 0,
                topBarProps: {
                    hidePrefix: true
                },
                theme: themeDark
            });
        }
    }
}
