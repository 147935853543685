import { LandingItemStep } from 'web/src/modules/landings/system/components/LandingListItem/types';
import useI18n from 'web/src/modules/i18n/composables/useI18n';
const { $translate } = useI18n();
export default function useLandingListItem(props) {
    let titleStep = '';
    if (props.step === LandingItemStep.TITLE) titleStep = `${$translate('WEB2_ANDROID_LANDING_STEP').value} ${props.index + 1}`;
    return {
        titleStep
    };
}
