import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import LandingBrand from 'web/src/modules/landings/system/components/LandingBrand/LandingBrand.vue';
import { LandingBrandSize } from 'web/src/modules/landings/system/components/LandingBrand/types';
import LandingSocialList from 'web/src/modules/landings/system/components/LandingSocialLink/LandingSocialList.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingFooterPanel',
    props: {
        socialList: {},
        brandHeight: {
            default: LandingBrandSize.HEIGHT_44
        },
        copyrightList: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['landing-footer-panel'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-footer-panel__panel'])
                }, [
                    _createVNode(LandingBrand, {
                        "brand-height": _ctx.brandHeight
                    }, null, 8, [
                        "brand-height"
                    ]),
                    _ctx.socialList ? (_openBlock(), _createBlock(LandingSocialList, {
                        key: 0,
                        list: _ctx.socialList
                    }, null, 8, [
                        "list"
                    ])) : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.copyrightList, (item, index)=>(_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: _normalizeClass(_ctx.$style['landing-footer-panel__copyright'])
                        }, [
                            _createElementVNode("span", null, _toDisplayString(item), 1)
                        ], 2))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingFooterPanel'
                ]
            ]);
        };
    }
});
