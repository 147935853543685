import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import LandingSocialLink from 'web/src/modules/landings/system/components/LandingSocialLink/LandingSocialLink.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingSocialList',
    props: {
        list: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['landing-social-link-list'])
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index)=>(_openBlock(), _createBlock(LandingSocialLink, {
                        key: index,
                        url: item.url,
                        target: item.target,
                        name: item.name
                    }, null, 8, [
                        "url",
                        "target",
                        "name"
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingSocialList'
                ]
            ]);
        };
    }
});
