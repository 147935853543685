const verticalImg = require('web/src/modules/landings/system/storybookMocks/vertical.png');
const squareImg = require('web/src/modules/landings/system/storybookMocks/image-square.png');
const horizontalImg = require('web/src/modules/landings/system/storybookMocks/horizontal.png');
const bgMobileImg = require('web/src/modules/landings/system/storybookMocks/bg-mob.png');
const bgDeskImg = require('web/src/modules/landings/system/storybookMocks/bg-desk.png');
const itemImageMascot = require('web/src/modules/landings/system/storybookMocks/mascot.png');
const item_1_1 = require('web/src/modules/landings/system/storybookMocks/1-1.png');
const item_1_2 = require('web/src/modules/landings/system/storybookMocks/1-2.png');
const item_1_3 = require('web/src/modules/landings/system/storybookMocks/1-3.png');
const item_2_1 = require('web/src/modules/landings/system/storybookMocks/2-1.png');
const item_2_2 = require('web/src/modules/landings/system/storybookMocks/2-2.png');
const item_2_3 = require('web/src/modules/landings/system/storybookMocks/2-3.png');
const item_3_1 = require('web/src/modules/landings/system/storybookMocks/3-1.png');
const item_3_2 = require('web/src/modules/landings/system/storybookMocks/3-2.png');
const item_3_3 = require('web/src/modules/landings/system/storybookMocks/3-3.png');
export { verticalImg, squareImg, horizontalImg, bgMobileImg, bgDeskImg, itemImageMascot, item_1_1, item_1_2, item_1_3, item_2_1, item_2_2, item_2_3, item_3_1, item_3_2, item_3_3 };
