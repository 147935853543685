import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { SystemIconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { VIcon } from '@components/v-icon';
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import FooterCopyright from 'web/src/modules/core/components/FooterCopyright/FooterCopyright.vue';
import LandingCouponsModal from 'web/src/modules/landings/submodules/coupons/pages/components/LandingCouponsModal/LandingCouponsModal.vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useLandingCouponsStore from 'web/src/modules/landings/submodules/coupons/store/useLandingCouponsStore';
import { useI18n, useI18nLocale } from 'web/src/modules/i18n/composables';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { useLandingImages } from 'web/src/modules/landings/submodules/coupons/useLandingImages';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingCouponsPage',
    setup (__props) {
        const isModalShown = ref(false);
        const siteConfigStore = useSiteConfigStore();
        const isTempCouponFeatureEnabled = toRef(siteConfigStore, 'isTempCouponFeatureEnabled');
        const router = useRouter();
        const landingCouponsStore = useLandingCouponsStore();
        const coupons = toRef(landingCouponsStore, 'coupons');
        const { locale } = useI18nLocale();
        const { $translate } = useI18n();
        const { srcCouponBackground, srcCouponMainImage, srcCouponSecondaryImage } = useLandingImages();
        onMounted(()=>{
            if (!isTempCouponFeatureEnabled.value) {
                router.push({
                    name: RouteName.ERROR_NOT_FOUND_404
                });
                return;
            }
            const encryptedCustomerId = router.getParam('id');
            if ('string' == typeof encryptedCustomerId) {
                landingCouponsStore.fetchTempCoupons(encryptedCustomerId);
                router.push({
                    name: RouteName.COUPONS
                });
            }
        });
        function onCLick() {
            isModalShown.value = true;
        }
        function onClose() {
            isModalShown.value = false;
        }
        const params = computed(()=>({
                coupons: `${coupons.value.length}`
            }));
        const tickets = computed(()=>LanguageMicroSettings.plural([
                $translate('WEB2_LANDING_COUPONS_TICKET_ONE').value,
                $translate('WEB2_LANDING_COUPONS_TICKET_THREE').value,
                $translate('WEB2_LANDING_COUPONS_TICKET_MANY').value
            ], coupons.value.length, locale.value));
        const getNumberOfCouponsTitle = computed(()=>`${$translate('WEB2_LANDING_COUPON_INFO_TITLE', params).value} ${tickets.value}`);
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['landing-coupons'])
            }, [
                _createVNode(_component_router_link, {
                    to: "/",
                    class: _normalizeClass(_ctx.$style['landing-coupons__header'])
                }, {
                    default: _withCtx(()=>[
                            _createVNode(VBrand, {
                                "is-landing": "",
                                "is-dark": ""
                            })
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-coupons__main'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-coupons__main-banner'])
                    }, [
                        _createVNode(VImage, {
                            src: _unref(srcCouponMainImage),
                            class: _normalizeClass(_ctx.$style['landing-coupons__main-img'])
                        }, null, 8, [
                            "src",
                            "class"
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['landing-coupons__main-bg'])
                        }, [
                            _createVNode(VImage, {
                                src: _unref(srcCouponBackground)
                            }, null, 8, [
                                "src"
                            ])
                        ], 2)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-coupons__main-info'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['landing-coupons__main-chevron'])
                        }, _toDisplayString(_ctx.$t('WEB2_LANDING_COUPON_CHEVRON')), 3),
                        _createVNode(VDynamicContent, {
                            class: _normalizeClass(_ctx.$style['landing-coupons__main-title']),
                            content: _ctx.$t('WEB2_LANDING_COUPON_MAIN_TITLE')
                        }, null, 8, [
                            "class",
                            "content"
                        ]),
                        _createVNode(VDynamicContent, {
                            class: _normalizeClass(_ctx.$style['landing-coupons__main-label']),
                            content: _ctx.$t('WEB2_LANDING_COUPON_MAIN_TEXT')
                        }, null, 8, [
                            "class",
                            "content"
                        ])
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-coupons__body'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-coupons__body-img'])
                    }, [
                        _createVNode(VImage, {
                            src: _unref(srcCouponSecondaryImage)
                        }, null, 8, [
                            "src"
                        ])
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-coupons__body-info'])
                    }, [
                        _createVNode(VDynamicContent, {
                            class: _normalizeClass(_ctx.$style['landing-coupons__body-title']),
                            content: getNumberOfCouponsTitle.value
                        }, null, 8, [
                            "class",
                            "content"
                        ]),
                        _createVNode(VDynamicContent, {
                            class: _normalizeClass(_ctx.$style['landing-coupons__body-label']),
                            content: _ctx.$t('WEB2_LANDING_COUPON_INFO_LABEL')
                        }, null, 8, [
                            "class",
                            "content"
                        ]),
                        coupons.value.length ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: _normalizeClass(_ctx.$style['landing-coupons__body-button']),
                            onClick: onCLick
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_LANDING_COUPON_BUTTON')), 1),
                            _createVNode(_unref(VIcon), {
                                name: _unref(SystemIconName).ARROW_ROUNDED_RIGHT
                            }, null, 8, [
                                "name"
                            ])
                        ], 2)) : _createCommentVNode("", true)
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-coupons__footer'])
                }, [
                    _createVNode(VBrand, {
                        class: _normalizeClass(_ctx.$style['landing-coupons__footer-brand']),
                        "is-landing": "",
                        "is-dark": ""
                    }, null, 8, [
                        "class"
                    ]),
                    _createCommentVNode("", true),
                    _createVNode(FooterCopyright)
                ], 2),
                _createVNode(LandingCouponsModal, {
                    coupons: coupons.value,
                    "is-modal-shown": isModalShown.value,
                    onClose: onClose
                }, null, 8, [
                    "coupons",
                    "is-modal-shown"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingCouponsPage'
                ]
            ]);
        };
    }
});
