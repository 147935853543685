import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VImage from 'web/src/components/Image/VImage/VImage.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingApps',
    props: {
        list: {},
        isCentered: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['landing-apps']]: true,
                    [_ctx.$style['landing-apps--centered']]: _ctx.isCentered
                })
            }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index)=>(_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "landing-apps__item"
                    }, [
                        _createVNode(VImage, {
                            height: "40px",
                            src: require(`web/src/modules/landings/system/components/LandingApps/images/${item.name}`)
                        }, null, 8, [
                            "src"
                        ])
                    ]))), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingApps'
                ]
            ]);
        };
    }
});
