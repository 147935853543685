import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onClick"
];
import LandingTitle from 'web/src/modules/landings/system/components/LandingTitle/LandingTitle.vue';
import { TitleKind } from 'web/src/modules/landings/system/components/LandingTitle/types';
import LandingLabel from 'web/src/modules/landings/system/components/LandingLabel/LandingLabel.vue';
import { LabelKind } from 'web/src/modules/landings/system/components/LandingLabel/types';
import useLandingListPanelTab from 'web/src/modules/landings/system/components/LandingListPanelTab/composables/useLandingListPanelTab';
import LandingListItem from 'web/src/modules/landings/system/components/LandingListItem/LandingListItem.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingListPanelTab',
    props: {
        title: {},
        label: {},
        list: {},
        listTabs: {}
    },
    setup (__props) {
        const { setActiveSlide, activeIndex, swiperMain, emitSlideChanged } = useLandingListPanelTab();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['landing-list-panel-tab'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-list-panel-tab__header'])
                }, [
                    _ctx.title ? (_openBlock(), _createBlock(LandingTitle, {
                        key: 0,
                        content: _ctx.title,
                        kind: _unref(TitleKind).SECONDARY
                    }, null, 8, [
                        "content",
                        "kind"
                    ])) : _createCommentVNode("", true),
                    _ctx.label ? (_openBlock(), _createBlock(LandingLabel, {
                        key: 1,
                        content: _ctx.label,
                        kind: _unref(LabelKind).SECONDARY
                    }, null, 8, [
                        "content",
                        "kind"
                    ])) : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['landing-list-panel-tab__panel'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['landing-list-panel-tab__tab'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listTabs, (itemTab, index)=>(_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: _normalizeClass({
                                    [_ctx.$style['landing-list-panel-tab__tab-item']]: true,
                                    [_ctx.$style['landing-list-panel-tab__tab-item--active']]: index === _unref(activeIndex)
                                }),
                                onClick: ($event)=>_unref(setActiveSlide)(index, itemTab.label)
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['landing-list-panel-tab__tab-icon']),
                                    style: _normalizeStyle({
                                        maskImage: `url(${itemTab.icon})`
                                    })
                                }, null, 6),
                                itemTab.label ? (_openBlock(), _createBlock(LandingLabel, {
                                    key: 0,
                                    "is-inverse": "",
                                    content: itemTab.label,
                                    "is-active": index === _unref(activeIndex),
                                    kind: _unref(LabelKind).TAB
                                }, null, 8, [
                                    "content",
                                    "is-active",
                                    "kind"
                                ])) : _createCommentVNode("", true)
                            ], 10, _hoisted_1))), 128))
                    ], 2),
                    (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['landing-list-panel-tab__body'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (insideList, insideListIndex)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(insideList.list, (item, itemIndex)=>(_openBlock(), _createElementBlock(_Fragment, {
                                        key: itemIndex
                                    }, [
                                        insideListIndex === _unref(activeIndex) ? (_openBlock(), _createBlock(LandingListItem, _mergeProps({
                                            key: 0,
                                            class: _ctx.$style['landing-list-panel-tab__list-item'],
                                            ref_for: true
                                        }, item), null, 16, [
                                            "class"
                                        ])) : _createCommentVNode("", true)
                                    ], 64))), 128))
                            ], 64))), 256))
                    ], 2))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'LandingListPanelTab'
                ]
            ]);
        };
    }
});
